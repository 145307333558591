import React from 'react';
import { Outlet } from 'react-router-dom';
import ServicesNavigation from './services-navigation';

const Services = () => {
  return (<>
    <ServicesNavigation />
    <Outlet />
    </>
  );
};

export default Services;