// src/pages/NotFound.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Box } from '@mui/material';
import logo from 'assets/full_logo_800x400.png'

const NotFound: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh" // This ensures the box takes up the full viewport height
      textAlign="center"
    >
      <Box
        component="img"
        src={logo}
        alt="Logo"
        sx={{
          width: '100%',
          maxWidth: '300px', // Adjust this value to control the maximum size of the logo
          height: 'auto',
          marginBottom: 2, // Adds some space between the logo and the text
        }}
      />
      <Typography variant="h4" component="h1" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="body1" gutterBottom>
        Sorry, the page you are looking for does not exist.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        component={Link} 
        to="/"
        sx={{ mt: 2 }} // Adds some top margin to the button
      >
        Go Back to Home
      </Button>
    </Box>
  );
};

export default NotFound;
