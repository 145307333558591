import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import SocialTray from '../social-tray';
import { grey } from '@mui/material/colors';

const PageFooter: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'grey.800',
        color: 'common.white',
        height: '60px', // Set a fixed height for the footer
        display: 'flex', // Use flexbox for vertical centering
        alignItems: 'center', // Center items vertically
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center', // Center items vertically
          width: '100%', // Take full width of the footer
          maxWidth: 'lg',
          margin: '0 auto',
          padding: '0 16px', // Add some horizontal padding
        }}
      >
        <Typography variant="body2" color={grey[400]}>
          © {new Date().getFullYear()} Redhead Green Thumb
        </Typography>
        <SocialTray color="light" />
      </Box>
    </Box>
  );
};

export default PageFooter;