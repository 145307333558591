import React from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from '@mui/material';

import { Phone, Email } from '@mui/icons-material';
import contactInfo from './contact-info';
import ServicesList from '../../components/services-list';

const OpenBusinessContent: React.FC = () => {
  return (
    <div className="contentFrame" style={{ position: 'relative' }}>
      <Typography variant="h1" gutterBottom>
        Contact Us
      </Typography>

      <Typography variant="body1" paragraph>
        We're always happy to hear from people interested in making their indoor spaces grow!
      </Typography>

      <Typography variant="body1" paragraph>
        Feel free to reach out to us:
      </Typography>

      <List
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { md: 'space-around' },
          alignItems: { md: 'flex-start' },
        }}
      >
        <ListItem sx={{ width: { md: 'auto' } }}>
          <ListItemIcon>
            <Phone />
          </ListItemIcon>
          <ListItemText
            primary="Give us a call"
            secondary={
              <Link
                href={`tel:${contactInfo.businessPhoneNumber}`}
                color="inherit"
              >
                {contactInfo.businessPhoneNumber}
              </Link>
            }
          />
        </ListItem>

        <ListItem sx={{ width: { md: 'auto' } }}>
          <ListItemIcon>
            <Email />
          </ListItemIcon>
          <ListItemText
            primary="Send us an email"
            secondary={
              <Link
                href={`mailto:${
                  contactInfo.businessEmail
                }?subject=${encodeURIComponent(
                  'Inquiry from redheadgreenthumb.com/contact'
                )}`}
                color="inherit"
              >
                {contactInfo.businessEmail}
              </Link>
            }
          />
        </ListItem>
      </List>

      <Typography variant="body1" paragraph>
        Let's make your space greener!
      </Typography>
      <Typography variant="body1" paragraph>
       If you just want to learn more about what we do and don't really want to reach out, checkout our services.
      </Typography>
      <ServicesList />
    </div>
  );
};

export default OpenBusinessContent;
