import { AppBar, Toolbar } from "@mui/material";

import { HeaderLinks } from "./header-links";
import { HeaderLogo } from "./header-logo";

export const PageHeader = () => {
    return (<AppBar position="fixed" sx={{ top:0, bottom:'auto', backgroundColor: 'white' }}>
        <Toolbar sx={{ flexGrow: 1}}>
          <HeaderLogo />
          <HeaderLinks />
        </Toolbar>
      </AppBar>
    );
}
