import React from 'react';
import { Box, IconButton, SvgIcon } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

interface SocialTrayProps {
  color?: 'primary' | 'secondary' | 'default' | 'inherit' | 'light';
}

const SocialTray: React.FC<SocialTrayProps> = ({ color = 'default' }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        component="a"
        href="https://www.instagram.com/rdhdgreenthmb/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
        sx={{
          color: color === 'light' ? 'grey.400' : 'inherit',
          '&:hover': {
            color: color === 'light' ? 'common.white' : 'primary.main',
          },
        }}
      >
        <InstagramIcon />
      </IconButton>
      <IconButton
        component="a"
        href="https://www.facebook.com/rdhdgreenthmb"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook"
        sx={{
          color: color === 'light' ? 'grey.400' : 'inherit',
          '&:hover': {
            color: color === 'light' ? 'common.white' : 'primary.main',
          },
        }}
      >
        <FacebookIcon />
      </IconButton>
    </Box>
  );
};

export default SocialTray;