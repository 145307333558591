import React from 'react';
import { Box, Typography, Button, Theme } from '@mui/material';

interface InfoSectionProps {
  header: string;
  body: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

const InfoSection: React.FC<InfoSectionProps> = ({ header, body, buttonText, onButtonClick }) => {
  return (
    <Box sx={{ maxWidth: '600px', margin: '20px auto', padding: '0 20px' }}>
      <Typography
        variant="h4"
        sx={(theme: Theme) => ({
          color: theme.palette.success.main, // Green color
          textAlign: 'left',
          marginBottom: '16px',
        })}
      >
        {header}
      </Typography>
      
      <Typography
        variant="body1"
        sx={{
          textAlign: 'justify',
          marginBottom: '24px',
        }}
      >
        {body}
      </Typography>
      
      {buttonText && onButtonClick && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={onButtonClick}
            sx={(theme: Theme) => ({
              backgroundColor: theme.palette.error.main, // Red color
              color: theme.palette.common.white,
              borderRadius: '20px', // Rounded corners
              '&:hover': {
                backgroundColor: theme.palette.error.dark,
              },
            })}
          >
            {buttonText}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default InfoSection;