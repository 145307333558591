import React from 'react';
import { getRotationDegrees } from './utils/getRotationDegrees';

const ClockNumbers = () => {
  
    const numbers = [];
    for (let i = 1; i <= 12; i++) {
      const radius = 95;
      const degrees = getRotationDegrees('hour', i) - 90;
      const angleRadians = (degrees * Math.PI) / 180;
      const yOffset = Math.sin(angleRadians)* radius;
      const xOffset = Math.cos(angleRadians) * radius;

      const numberStyle = {
        fontSize: '10px',
        fontWeight: 'bold',
        zIndex: 10,
      };

      const outerStyle = {
        top: '50%',
        left: '50%',
        transform: `translate(${xOffset}px, ${yOffset}px) rotate(${i * 30}deg)`,
        transformOrigin: '50% 100%',
        zIndex: 5,
      };

      numbers.push(
        <div key={degrees} style={{ position: 'absolute', ...outerStyle }}>
          <div key={i} style={{ position: 'absolute', ...numberStyle }}>
            {i}
          </div>
        </div>
      );
    }
    return <div style={{ position: 'absolute', height: '100%', width: '100%' }}>{numbers}</div>;
  
};

export default ClockNumbers;
