export interface ContactInfo {
  businessTimeZone: string;
  businessEmail: string;
  businessPhoneNumber: string;
  instagram: string;
  facebook:string;
}

export const contactInfo: ContactInfo = {
  businessTimeZone: 'America/Chicago',
  businessEmail: 'laurie@redheadgreenthumb.com',
  businessPhoneNumber: '(605) 620-1920',
  instagram: 'https://www.instagram.com/rdhdgreenthmb/',
  facebook: 'https://www.facebook.com/rdhdgreenthmb'
};

export default contactInfo;
