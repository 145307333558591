import React from 'react';
import { PageHeader } from './PageHeader/header.layout';
import SEO from './seo';
import { Outlet, useLocation } from 'react-router-dom';
import PageFooter from './PageFooter/footer.layout';

const Layout = () => {
  const location = useLocation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <SEO key={location.pathname} />
      <PageHeader />
      <main style={{ flex: 1, overflowY: 'auto', margin: '64px 0' }}>
        <Outlet />
      </main>
      <PageFooter />
    </div>
  );
};

export default Layout;