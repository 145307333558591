import { Grid, Typography } from '@mui/material';
import AnalogClock from '../../components/AnalogClock/analog-clock';
import contactInfo from './contact-info';

interface BusinessHoursProps {
  isBusinessOpen: boolean;
}

const BusinessHours: React.FC<BusinessHoursProps> = ({ isBusinessOpen }) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justifyContent="center" // This ensures content is centered within the Grid
    sx={{ height: '100%' }} // This makes the Grid take full height of its container
  >
    <Grid item>
      <AnalogClock />
    </Grid>
    <Grid item>
      <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
        Business Hours
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        {isBusinessOpen ? (
          <span style={{ color: 'green' }}>We're Open Now!</span>
        ) : (
          <span style={{ color: 'red' }}>Currently Closed</span>
        )}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        Weekdays: 9:00 AM - 5:00 PM (Central Time)
      </Typography>
    </Grid>
    <Grid item>
      <Typography
        variant="body1"
        sx={{ marginTop: '20px', textAlign: 'center', maxWidth: '600px' }}
      >
        We are currently tending to our own plants. While we would love to speak
        with you, no one is around to answer the phone at the moment. Feel free
        to send an email to{' '}
        <a
          href={`mailto:${contactInfo.businessEmail}?subject='After hours inquiry from redheadgreenthumb.com/contact'`}
        >
          {contactInfo.businessEmail}
        </a>
        . We will get back to you as soon as possible.
      </Typography>
    </Grid>
  </Grid>
);

export default BusinessHours;
