import { Link } from '@mui/material';
import logo from 'assets/text_only_851x315.png';

export const HeaderLogo = () => {
  return (
    <div className="headerLogo">
      <Link href="/">
        <img
          src={logo}
          alt="Redhead Green Thumb"
          style={{ height: '50px', marginRight: '10px' }}
        />
      </Link>
    </div>
  );
};
