import React from "react";
import { timeUnits } from "../../../types";
import { getRotationDegrees } from "./getRotationDegrees";

export const getClockHandStyle = (
    time: Date,
    unit: timeUnits
  ): React.CSSProperties => {
    const unitProperties = {
        hour: {
          getTime: () => time.getHours() + time.getMinutes() / 60,
          length: 50,
          thickness: 4,
          color: 'black',
        },
        minute: {
          getTime: () => time.getMinutes() + time.getSeconds() / 60,
          length: 70,
          thickness: 2,
          color: 'black',
        },
        second: {
          getTime: () => time.getSeconds() + time.getMilliseconds() / 1000,
          length: 80,
          thickness: 1,
          color: 'blue',
        },
      };

    const properties = unitProperties[unit];
    const degrees = getRotationDegrees(
      unit,
      properties.getTime()
    );

    return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -100%) rotate(${degrees}deg)`,
        transformOrigin: '50% 100%',
        backgroundColor: properties.color,
        height: `${properties.length}px`,
        width: `${properties.thickness}px`,
    };
  };