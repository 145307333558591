import React from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  LocalFlorist,
  ShoppingCart,
  Description,
  Phone,
} from '@mui/icons-material';

const DIYService: React.FC = () => {
  return (
    <div className="contentFrame">
      <Typography variant="h1" gutterBottom>
        DIY Service
      </Typography>

      <Typography variant="body1" paragraph>
        Our 'do it yourself' (DIY) service offers a personalized approach to
        plant selection and care, allowing you to take charge of your green
        space with expert guidance. It's more of a 'let's work together'
        approach, but you get all the glory in the end.
      </Typography>

      <List>
        <ListItem>
          <ListItemIcon>
            <LocalFlorist />
          </ListItemIcon>
          <ListItemText
            primary="Customized Selection"
            secondary="We select plants and containers together, tailored for specific areas of your space."
          />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText
            primary="Direct Purchase"
            secondary="You buy the plants and containers outright, giving you immediate ownership."
          />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <Description />
          </ListItemIcon>
          <ListItemText
            primary="Care Instructions"
            secondary="We provide you with detailed instructions for caring for your new plants."
          />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <Phone />
          </ListItemIcon>
          <ListItemText
            primary="On-Demand Consultation"
            secondary="Should you need help, you can call us on an as-needed basis for an hourly rate to consult on any struggling plants."
          />
        </ListItem>
      </List>

      <Typography variant="body1" paragraph>
        This service is perfect for those who want to be hands-on with their
        plants but appreciate expert guidance and support when needed.
      </Typography>
    </div>
  );
};

export default DIYService;
