import React from 'react';
import { Box, Fade, Typography } from '@mui/material';

interface ClosedBusinessContentProps {
  showClosedSign: boolean;
  setShowClosedSign: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClosedBusinessContent: React.FC<ClosedBusinessContentProps> = ({
  showClosedSign,
  setShowClosedSign,
}) => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowClosedSign(false);
    }, 5000); // 5 seconds delay before fading out

    return () => clearTimeout(timer);
  }, [setShowClosedSign]);

  return (
    <Fade in={showClosedSign} timeout={1000}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <Box
          sx={{
            width: '600px',
            height: '300px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              border: '10px solid white',
              borderRadius: '5px',
              backgroundColor: 'black',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              boxShadow: '0 0 15px 5px rgba(0,0,0,0.125)',
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "'Brush Script MT', cursive",
                color: 'white',
                marginBottom: '-0.25em',
                rotate: '-5deg',
                marginLeft: '-1.5em',
                fontSize: '3.5em',
              }}
            >
              Sorry...
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 'bold',
                fontSize: '3em',
                color: 'white',
                lineHeight: 1,
                textTransform: 'uppercase',
                marginLeft: '2em',
                marginBottom: '-0.25em',
              }}
            >
              We're
            </Typography>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 'bolder',
                color: '#ff0000',
                textTransform: 'uppercase',
                lineHeight: 1,
                marginTop: '0.1em',
                fontSize: '5em',
              }}
            >
              Closed
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default ClosedBusinessContent;
