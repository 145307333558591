import {
  List,
  ListItem,
  Box,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import routes from '../Routes';

const ServicesList: React.FC = () => {
  const serviceRoutes =
    routes.find((route) => route.path === '/services')?.children || [];
  return (
    <List>
      {serviceRoutes.map((route) => {
        if (route.omitFromContactPage) return null;
        const Icon = route.icon;
        return (
          <ListItem
            key={route.path}
            alignItems="flex-start"
            sx={{
              flexDirection: 'column',
              mb: 2,
              border: '1px solid #e0e0e0',
              borderRadius: '4px',
              padding: 2,
            }}
          >
            <Box sx={{ display: 'flex', width: '100%', mb: 1 }}>
              {Icon && (
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={route.contactPageLabel || route.seo?.title}
                secondary={route.seo?.description}
              />
            </Box>
            <Box sx={{ alignSelf: 'flex-end' }}>
              <Button
                component={RouterLink}
                to={`/services/${route.path}`}
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  borderRadius: '20px',
                  padding: '5px 15px',
                }}
              >
                Learn More
              </Button>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ServicesList;
