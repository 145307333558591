import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ClosedBusinessContent from './closed-sign';
import BusinessHours from './business-hours';
import contactInfo from './contact-info';
import OpenBusinessContent from './open-content';


const getBusinessStatus = (businessTimeZone: string): boolean => {
  const currentDate = new Date().toLocaleString('en', {
    timeZone: businessTimeZone,
  });
  const currentDay = new Date(currentDate).getDay();
  const currentHour = new Date(currentDate).getHours();

  const isBusinessDay = currentDay >= 1 && currentDay <= 5;
  const isBusinessHour = currentHour >= 9 && currentHour < 18;
  const isBusinessOpen = isBusinessDay && isBusinessHour;

  return isBusinessOpen;
};

const ContactUs: React.FC = () => {
  const isBusinessOpen = getBusinessStatus(
    contactInfo.businessTimeZone
  );
  const [showClosedSign, setShowClosedSign] = useState(!isBusinessOpen);

  if (
    !Intl ||
    !Intl.DateTimeFormat ||
    !Intl.DateTimeFormat().resolvedOptions().timeZone
  ) {
    return <UnsupportedBrowserMessage />;
  }

  return (
    <Box 
      sx={{ 
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 60px)', // Adjust this value based on your header and footer height
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!isBusinessOpen && (
        <ClosedBusinessContent
          showClosedSign={showClosedSign}
          setShowClosedSign={setShowClosedSign}
        />
      )}
      <Box
        sx={{
          filter: showClosedSign ? 'blur(5px)' : 'none',
          transition: 'filter 1s ease-in-out',
          width: '100%',
          maxWidth: '800px', // Adjust this value as needed
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isBusinessOpen ? (
          <OpenBusinessContent />
        ) : (
          <BusinessHours isBusinessOpen={isBusinessOpen} />
        )}
      </Box>
    </Box>
  );
};

const UnsupportedBrowserMessage: React.FC = () => (
  <Box>
    <Typography variant="h4" gutterBottom>
      Contact Us
    </Typography>
    <Typography variant="body1" gutterBottom>
      We're sorry, but your browser does not support the features required for
      this page. Please use a more modern browser.
    </Typography>
  </Box>
);

export default ContactUs;
