import React from 'react';
import InfoSection from '../../components/StyledGrids/info-section';
import { Navigate, useNavigate } from 'react-router-dom';

export default function WhereYouWork() {
  const navigate = useNavigate();
  const infoContent = {
    header: 'Where You Work',
    body: 'Whether you run a retail space, an office with foot traffic or an office for you and your employees, plants can be transformative. Living, breathing plants make our spaces feel more inviting help improve the health of our built environments.',
    // buttonText: 'For Work',
    // onButtonClick: () => navigate('at-work'),
  };
  return <InfoSection {...infoContent} />;
}
