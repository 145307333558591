import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Theme, PaletteColor } from '@mui/material/styles';

export type CustomColor = 
  | 'primary' 
  | 'secondary' 
  | 'error' 
  | 'warning' 
  | 'info' 
  | 'success'
  | 'text.primary'
  | 'text.secondary'
  | 'text.disabled'
  | 'white'
  | `blue.${string}`
  | `yellow.${string}`
  | `brown.${string}`
  | `grey.${string}`;

interface ColoredTypographyProps extends TypographyProps {
  color?: CustomColor;
}

const ColoredTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'color',
})<ColoredTypographyProps>(({ theme, color }) => ({
  color: color
    ? color.includes('.')
      ? (theme.palette[color.split('.')[0] as keyof Theme['palette']] as unknown as Record<string, string>)[color.split('.')[1]]
      : ((theme.palette[color as keyof Theme['palette']] as PaletteColor)?.main || 
         theme.palette[color as keyof Theme['palette']] as string)
    : 'inherit',
}));

export default ColoredTypography;