import React from 'react';
import { Grid, Typography, Card, CardContent, Divider, Box } from '@mui/material';
import {
  AttachMoney as AttachMoneyIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import ColoredTypography, {
  CustomColor,
} from '../../../components/ColoredTypography';

interface PricingTier {
  range: string;
  price: string;
  icon?: React.ElementType;
  color: CustomColor;
  description?: string;
}

const PlantMaintenancePricing: React.FC = () => {
  const pricingTiers: PricingTier[] = [
    { range: '1-5 plants', price: '$50/month', color: 'primary' },
    { range: '6-10 plants', price: '$60/month', color: 'primary' },
    { range: '11-20 plants', price: '$80/month', color: 'primary' },
    {
      range: 'More?',
      price: "Let's chat!",
      icon: ChatIcon,
      color: 'secondary',
      description:
        'I offer a selection of new plants that will thrive in your space, all available at competitive prices.',
    },
  ];

  return (
    <Grid container spacing={0.5}>
      {pricingTiers.map((tier, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          <Grid item xs={12}>
            <Card elevation={0} sx={{ backgroundColor: 'transparent' }}>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 2,
                  }}
                >
                  <ColoredTypography
                    variant="h6"
                    color={tier.color as CustomColor}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {tier.icon && <tier.icon sx={{ mr: 1 }} />}
                    {tier.range}: {tier.price}
                  </ColoredTypography>
                  {tier.description && (
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                      {tier.description}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default PlantMaintenancePricing;
