import { Link } from "@mui/material"

export const HeaderLinks = () => {
    const pageLinks = [
        {
            key: 'services',
            path: '/services',
            displayValue: 'Services'
        },
        {
            key: 'about',
            path: '/about',
            displayValue: 'About Us'
        },
        {
            key: 'contact',
            path: '/contact',
            displayValue: 'Contact'
        },
    ]

    return (<div className='headerLinks'>
        {pageLinks.map((pageLink) => {
            return <Link key={pageLink.key} href={pageLink.path}>{pageLink.displayValue}</Link>
        })}
      </div>)
}